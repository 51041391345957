<template>
  <div>
      <div>
          门店新客
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>